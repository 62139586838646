const calculateTotalWorked = (checkInDatetime, checkOutDatetime) => {
  const checkIn = new Date(checkInDatetime);
  const checkOut = new Date(checkOutDatetime);
  const diffInMilliseconds = checkOut - checkIn;
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000); // Convert milliseconds to minutes

  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  const formattedTime = `${hours}h ${String(minutes).padStart(2, '0')}m`;
  return formattedTime;
};

export default calculateTotalWorked;