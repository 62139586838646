import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Container, Divider, Typography } from '@mui/material'
import { Checkbox, DatePicker } from 'antd'
import { DownloadOutlined } from '@mui/icons-material';
import axios from 'axios';
import { SERVER_URL } from '../../common/config';
import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import moment from 'moment';
import calculateTotalWorked from '../../utils/calculateTotalWorked';
import getAddressFromCoordinates from '../../utils/getAddressFromCordinate';
import { utils, writeFile } from 'xlsx';

const downloadOptions = ['Absent', 'Visiting Reason', 'Schedule Report', 'Outcome Report', 'Visiting Reason Report'];

const DownloadPage = () => {
  const [checkedList, setCheckedList] = useState(downloadOptions);
  const [filter, setFilter] = useState({
    start_date: null,
    end_date: null
  });

  const checkAll = downloadOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < downloadOptions.length;

  const handleChangeCheckbox = (list) => {
    setCheckedList(list);
  };

  const handleCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? downloadOptions : []);
  };

  const handleChangeDate = (e) => {
    let start_date;
    let end_date;
    if (e !== null) {
      const start = new Date(e[0])
      const end = new Date(e[1])

      // create a new Date object with the same date as the given date object, but with time set to 00:00:00
      start_date = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 1);

      // create a new Date object with the same date as the given date object, but with time set to 23:59:59
      end_date = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
    } else {
      start_date= new Date(null);
      end_date = new Date() + 1;
    }

    setFilter({
      ...filter,
      start_date: start_date,
      end_date: end_date
    })
  }

  function formatDate(dateString) {
    if (!dateString) {
      return '';
    }

    const datetime = moment(dateString);
    
    if (!datetime.isValid()) {
      return ''; // Handle invalid date strings
    }

    return datetime.format('h:mm A, D MMM YYYY');
  }

  const getVisitingReasonData = async (type) => {
    if (type === 'csv') {
      try {
        const { data } = await axios.post(`${SERVER_URL}getScheduleWithFilter`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const parser = new Parser({
          fields: ["schedule_id","full_name","client_entity_name","schedule_datetime","predicted_time_spent","notes","upload_picture","check_in_datetime","check_out_datetime","reason","isLate","exceed_time_limit","signature","visiting_reason_name","outcome_id","outcome_name"],
        });
        const csv = parser.parse(data.data);
    
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'visiting-reason-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getScheduleWithFilter`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const mappedData = data.data?.map?.((item) => {
          return {
            'Employee': item.full_name,
            'Client': item.client_entity_name,
            'Date Created': item.schedule_datetime !== "0000-00-00 00:00:00" ? formatDate(item.schedule_datetime) : '',
            'Checkin': item.check_in_datetime !== "0000-00-00 00:00:00" ? formatDate(item.check_in_datetime) : '',
            'Checkout': item.check_out_datetime !== "0000-00-00 00:00:00" ? formatDate(item.check_out_datetime) : '',
            'Present': (item.check_in_datetime && item.check_in_datetime !== "0000-00-00 00:00:00") ? "Yes" : "No"
          }
        })
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Visiting Reason");
  
        writeFile(workbook, "visiting-reason-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const getAbsentData = async (type) => {
    if (type === 'csv') {
      try {
        const { data } = await axios.post(`${SERVER_URL}getAllAbsent`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
  
        const parser = new Parser();
        const csv = parser.parse(data.payload.data);
  
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'absent-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getAllAbsent`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const mappedData = await Promise.all(data.payload.data.map( async item => {
          return {
            'Name': item.full_name,
            'Clockin': formatDate(item.check_in_datetime),
            'Clockout': formatDate(item.check_out_datetime),
            'Hours': item.check_out_datetime ? calculateTotalWorked(item.check_in_datetime, item.check_out_datetime) : '',
            'In Photo': item.check_in_image,
            'Out Photo': item.check_out_image,
            'In Location': await getAddressFromCoordinates({latitude: item.check_in_lat ?? 0, longitude: item.check_in_long ?? 0}),
            'Out Location': await getAddressFromCoordinates({latitude: item.check_out_lat ?? 0, longitude: item.check_out_long ?? 0}) ?? '',
            'Overtime': item.overtime_notes
          }
        }))
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Absent");
  
        writeFile(workbook, "absent-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  }
  const getScheduleReportData = async (type) => {
    if (type === 'csv') {
      try {
        const { data } = await axios.post(`${SERVER_URL}getScheduleReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id'),
          branch_id: localStorage.getItem('branch_id'),
        })
        const parser = new Parser(Object.keys(data.data));
        const csv = parser.parse(data.data);
    
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'schedule-report-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getScheduleReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id'),
          branch_id: localStorage.getItem('branch_id'),
        })
        const mappedData = data.data.map(item => {
          return {
            'Employee': item.full_name,
            'Number of Schedules': item.schedule_number,
            'Success': item.success,
            'Success Percentage': item.percentage,
          }
        })
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Schedule Report");
  
        writeFile(workbook, "schedule-report-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  }
  const getOutcomeReportData = async (type) => {
    if (type === 'csv') {
      try {
        const { data } = await axios.post(`${SERVER_URL}getOutcomeReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const parser = new Parser();
        const csv = parser.parse(data.data);
    
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'outcome-report-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getOutcomeReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const mappedData = data.data.map(item => {
          return {
            'Outcome': item.name,
            'Count': item.total_schedule,
          }
        })
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Outcome Report");
  
        writeFile(workbook, "outcome-report-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  }
  const getVisitingReportData = async (type) => {
    if (type === 'csv') {
      try {
        const { data } = await axios.post(`${SERVER_URL}getVisitingReasonReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const parser = new Parser();
        const csv = parser.parse(data.data);
    
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'visiting-reason-report-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getVisitingReasonReport`, {
          ...filter,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const mappedData = data.data.map(item => {
          return {
            'Visiting Reason': item.name,
            'Count': item.total_schedule,
          }
        })
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Visiting Reason Report");
  
        writeFile(workbook, "visiting-reason-report-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleDownload = async (type) => {
    if (checkedList.includes('Absent')) {
      getAbsentData(type);
    }
    
    if (checkedList.includes('Visiting Reason')) {
      getVisitingReasonData(type);
    }

    if (checkedList.includes('Schedule Report')) {
      getScheduleReportData(type);
    }

    if (checkedList.includes('Outcome Report')) {
      getOutcomeReportData(type);
    }

    if (checkedList.includes('Visiting Reason Report')) {
      getVisitingReportData(type);
    }
  }

  return (
    <Container>
      <Typography fontSize="22px" fontWeight={700} marginBottom="22px">Download All</Typography>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: '400px', marginBottom: '22px'}}>
        <Typography>Select Date</Typography>
        <DatePicker.RangePicker onChange={(e) => handleChangeDate(e)} />
      </Box>
      <Checkbox indeterminate={indeterminate} onChange={handleCheckAllChange} checked={checkAll}>
        Check all
      </Checkbox>
      <Divider sx={{ marginY: '12px' }} />
      <Checkbox.Group style={{ flexDirection: 'column', gap: '6px' }} options={downloadOptions} value={checkedList} onChange={handleChangeCheckbox} />
      <Box sx={{ marginTop: '18px' }}>
        <ButtonGroup color="primary" aria-label="Basic button group" disabled={!checkedList.length || !filter.start_date || !filter.end_date}>
          <Button
            startIcon={<DownloadOutlined />}
            size="small"
            variant="outlined"
            onClick={() => handleDownload('csv')}
          >
            Download CSV
          </Button>
          <Button
            startIcon={<DownloadOutlined />}
            size="small"
            variant="contained"
            onClick={() => handleDownload('xlsx')}
          >
            Download Excel
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  )
}

export default DownloadPage