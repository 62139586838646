import axios from "axios";
import { GOOGLE_MAP_API_KEY } from "../common/config";

const getAddressFromCoordinates = async ({latitude, longitude}, isFormattedAddress = false) => {
  if (!latitude || !longitude) {
    return '';
  }
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAP_API_KEY}`,
    );
    if (response.data.status === 'OK') {
      // console.log(latitude, longitude, response.data.results, 'response.data.results')
      if (isFormattedAddress) {
        const formattedAddress = response.data.results?.[0]?.formatted_address
        if (formattedAddress) {
          return formattedAddress;
        }
      }
      const addressComponents = response.data.results?.[0]?.address_components;
  
      let district = '';
      let subdistrict = '';
      let city = '';
  
      addressComponents.forEach((component) => {
        if (component.types.includes('administrative_area_level_2')) {
          district = component.long_name;
        } else if (component.types.includes('sublocality')) {
          subdistrict = ', ' + component.long_name;
        } else if (component.types.includes('locality')) {
          city = ', ' + component.long_name;
        }
      });
  
      return `${district}${subdistrict}${city}`;
    } else {
      console.error('Geocoding request failed');
    }
  } catch (error) {
    console.log(error)
    throw error
  }
};

export default getAddressFromCoordinates;