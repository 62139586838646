import React, { useState, useEffect } from 'react';
import { SERVER_URL } from '../../common/config'

import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { GOOGLE_MAP_API_KEY } from '../../common/config';
import Geocode from "react-geocode";
import useStyles from "./styles";
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import { Button, DatePicker as DatePickerAnt, Tag, Typography } from 'antd';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import FileSaver from 'file-saver';
import { Parser } from 'json2csv';
import { Space, Table, Modal, Switch } from 'antd';
import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import getAddressFromCoordinates from '../../utils/getAddressFromCordinate';
import { utils, writeFile } from 'xlsx';

Geocode.setApiKey(GOOGLE_MAP_API_KEY);

function AbsentView() {
    var classes = useStyles();
  const [absentData, setAbsentData] = useState([]);
  const { Text } = Typography;
  const [filterList, setFilterList] = useState({
    limit: 10,
    offset: 0,
    full_name: null,
    start_date: null,
    end_date: null
  })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadCSV, setDownloadCSV] = useState(false);


  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${SERVER_URL}getAllAbsent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: localStorage.getItem('company_id'),
          ...filterList
        })
      });


      const res = await response.json();
      const data = await Promise.all(
        res.payload?.data?.map?.(async record => ({
          ...record,
          check_in_datetime: formatDate(record.check_in_datetime),
          check_out_datetime: formatDate(record.check_out_datetime),
          totalWorked: record.check_out_datetime ? calculateTotalWorked(record.check_in_datetime, record.check_out_datetime) :'--h --m',
          check_in_address: await getAddressByCoordinates(record.check_in_lat, record.check_in_long),
          check_out_address: 
            await getAddressByCoordinates(record.check_out_lat ?? 0, record.check_out_long ?? 0) ?? '',
          user_status: getStatus(record.check_in_datetime, record.check_out_datetime),
        }))
      );

      setAbsentData({
        ...res.payload,
        data
      });
    }

    fetchData();
  }, [filterList]);

  const calculateTotalWorked = (checkInDatetime, checkOutDatetime) => {
    const checkIn = new Date(checkInDatetime);
    const checkOut = new Date(checkOutDatetime);
    const diffInMilliseconds = checkOut - checkIn;
    const diffInMinutes = Math.floor(diffInMilliseconds / 60000); // Convert milliseconds to minutes
  
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
  
    const formattedTime = `${hours}h ${String(minutes).padStart(2, '0')}m`;
    return formattedTime;
  };

  const handleScrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // You can adjust the duration of the scroll animation
      smooth: 'easeInOutQuart', // You can choose different easing functions
    });
  };

  const handleDownload = async () => {
    let notLimitedFilterList = filterList;
    notLimitedFilterList.limit = 999;
    if (isDownloadCSV) {
      try {
        const { data } = await axios.post(`${SERVER_URL}getAllAbsent`, {
          ...notLimitedFilterList,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
  
        const parser = new Parser();
        const csv = parser.parse(data.payload.data);
  
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        FileSaver.saveAs(csvData, 'absent-data.csv');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const { data } = await axios.post(`${SERVER_URL}getAllAbsent`, {
          ...notLimitedFilterList,
          limit: null,
          offset: null,
          company_id: localStorage.getItem('company_id')
        })
        const mappedData = await Promise.all(data.payload.data.map( async item => {
          return {
            'Name': item.full_name,
            'Clockin': formatDate(item.check_in_datetime),
            'Clockout': formatDate(item.check_out_datetime),
            'Hours': item.check_out_datetime ? calculateTotalWorked(item.check_in_datetime, item.check_out_datetime) : '',
            'In Photo': item.check_in_image,
            'Out Photo': item.check_out_image,
            'In Location': await getAddressFromCoordinates({latitude: item.check_in_lat ?? 0, longitude: item.check_in_long ?? 0}),
            'Out Location': await getAddressFromCoordinates({latitude: item.check_out_lat ?? 0, longitude: item.check_out_long ?? 0}) ?? '',
            'Overtime': item.overtime_notes
          }
        }))
        const worksheet = utils.json_to_sheet(mappedData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Absent");
  
        writeFile(workbook, "absent-data.xlsx", { compression: false });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          paddingTop: "5px",
          paddingBottom: "5px",
          fontSize: ".8125rem",
          height:"35px",
          textTransform: 'lowercase',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          textTransform: 'lowercase', // Remove the all caps style
        },
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
        fontSize: ".8125rem",
        textTransform: 'lowercase',
      },
    },
  });

  const handleChange = async (e, field) => {

    let start_date = null;
    let end_date = null;

    if (field == 'start_date') {
      if (e !== null) {
        const date = new Date(e);
  
        // create a new Date object with the same date as the given date object, but with time set to 00:00:00
        const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 1);
  
        start_date = startOfDay ? moment(startOfDay).format("YYYY-MM-DD HH:mm") : null
      }

      setFilterList({
        ...filterList,
        offset: 0,
        start_date: start_date
      })
    }

    if (field == 'end_date') {
      if (e !== null) {
        const date = new Date(e);
  
        // create a new Date object with the same date as the given date object, but with time set to 23:59:59
        const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        
        end_date = endOfDay ? moment(endOfDay).format("YYYY-MM-DD HH:mm") : null
      }

      setFilterList({
        ...filterList,
        offset: 0,
        end_date: end_date
      })

      const response = await fetch(`${SERVER_URL}getAllAbsent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          company_id: localStorage.getItem('company_id'),
          ...filterList
        })
      });


      const res = await response.json();
      const data = await Promise.all(
        res.payload?.data?.map?.(async record => ({
          ...record,
          check_in_datetime: formatDate(record.check_in_datetime),
          check_out_datetime: formatDate(record.check_out_datetime),
          totalWorked: record.check_out_datetime ? calculateTotalWorked(record.check_in_datetime, record.check_out_datetime) : '--h --m',

          check_in_address: await getAddressByCoordinates(record.check_in_lat, record.check_in_long),
          check_out_address: 
            await getAddressByCoordinates(record.check_out_lat ?? 0, record.check_out_long ?? 0) ?? '',
          user_status: getStatus(record.check_in_datetime, record.check_out_datetime),
        }))
      );

      setAbsentData({
        ...res.payload,
        data
      });
    }
  }

  const columns = [
    { name: 'full_name', label: 'Name' },
    { name: 'check_in_datetime', label: <p style={{ textTransform: 'capitalize' }}>Checkin</p> },
    { name: 'check_out_datetime', 
    label: <p style={{ textTransform: 'capitalize' }}>Checkout</p> ,
      options: {
        customBodyRender: (value, tableMeta) => {
          const checkOutDateTime = tableMeta.rowData[2];
        
          if (!checkOutDateTime) {
            return <Tag color="warning">Pending</Tag>; // Return null if check_in_datetime is not defined
          }        
          return value;
        }
      }
    },
    { name: 'totalWorked', label: <p style={{ textTransform: 'capitalize' }}>Hours</p> },
    { 
      name: 'check_in_image',
      label: <p style={{ textTransform: 'capitalize' }}>In</p> ,
      options: {
        filter: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const checkInDatetime = tableMeta.rowData[1];
        
          if (!checkInDatetime) {
            return null; // Return null if check_in_datetime is not defined
          }

          if( value === null ){
            return <Tag color="error">No photo</Tag>;
          }
    
          return (
            <a href={`${SERVER_URL}upload/${value}`} target="_blank" rel="noopener noreferrer">
              <Button type="primary" size="small">View</Button>
            </a>
          );
        }
      }, 
    },
    { 
      name: 'check_out_image',
      label: <p style={{ textTransform: 'capitalize' }}>Out</p>,
      options: {
        filter: false,
        download: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const checkOutDateTime = tableMeta.rowData[2];
        
          if (!checkOutDateTime) {
            return <Tag color="warning">Pending</Tag>; // Return null if check_in_datetime is not defined
          }

          if( value === null ){
            return <Tag color="error">No photo</Tag>;
          }
        
          return (
            <a href={`${SERVER_URL}upload/${value}`} target="_blank" rel="noopener noreferrer">
              <Button type="primary" size="small">View</Button>
            </a>
          );
        }
      }, 
    },
    {
      name: 'check_in_address',
      label: <p style={{ textTransform: 'capitalize' }}>In-Location</p>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <CustomTableCell value={value} />;
        },
      },
    },
    {
      name: 'check_out_address',
      label:<p style={{ textTransform: 'capitalize' }}>Out-Location</p>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <CustomTableCell value={value} />;
        },
      },
    },
    {
      name: 'overtime_notes',
      label: <p style={{ textTransform: 'capitalize' }}>Overtime</p>,
      options: {
        filter: false,
        sort: false,
      }
    }
  ];
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const options = {
    filter: true,
    selectableRows: 'none',
    responsive: 'standard',
    search: false,
    filter: false,
    print: false,
    serverSide: true,
    pagination: true,
    count:  absentData.total,
    download: true,
    downloadOptions: {
      filename: 'absent_data.xlsx'
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const body = { company_id: localStorage.getItem('company_id') }
      let notLimitedFilterList = filterList;
      notLimitedFilterList.limit = 999;

      fetch(`${SERVER_URL}getAllAbsent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          company_id: localStorage.getItem('company_id'),
          ...notLimitedFilterList
        })
      })
      .then(res => res.json())
      .then(async res => {
        const fields = [];
        columns.map(item => {
          if (item.label !== "Action" && item.download) {
            fields.push({
              label: item.label,
              value: item.name
            })
          }
        })
        const opts = { fields };
        try {
          const data = await Promise.all(res.payload.data.map( async item => {
            return {
              ...item,
              check_in_datetime: formatDate(item.check_in_datetime),
              check_out_datetime: formatDate(item.check_out_datetime),
              totalWorked: item.check_out_datetime ? calculateTotalWorked(item.check_in_datetime, item.check_out_datetime) : '--h --m',

              check_in_address: await getAddressByCoordinates(item.check_in_lat, item.check_in_long),
              check_out_address: 
                await getAddressByCoordinates(item.check_out_lat ?? 0, item.check_out_long ?? 0) ?? '',
            }
          }))

          const parser = new Parser(opts);
          const csv = parser.parse(data);

          const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          FileSaver.saveAs(csvData, 'absent_data.xlsx');
        } catch (err) {
          console.error(err);
        }
      })
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setFilterList({
            ...filterList,
            offset: tableState.page * filterList.limit
          })
          break;
        case 'changeRowsPerPage':
          setFilterList({
            ...filterList,
            limit: tableState.rowsPerPage
          })
          break;
        default:
          console.log('action not handled.');
      }
    },
  };

  function TruncateText(props) {
    const [isTruncated, setIsTruncated] = useState(true);
  
    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };
  
    const textToShow = isTruncated
      ? props.text.slice(0, 20) + '...'
      : props.text;
  
    return (
      <span>
        {textToShow}
        {props.text.length > 20 && (
          <a style={{color: 'blue', cursor: 'pointer', textDecoration:'underline'}} onClick={toggleTruncate}>
            {isTruncated ? 'View More' : 'View Less'}
          </a>
        )}
      </span>
    );
  }
  
  function CustomTableCell(props) {
    const { value } = props;
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <MuiTableCell style={{width:'150px', border:'none'}} {...props}>
        {typeof value === 'string' && value.length > 20 ? (
          <div>
            <TruncateText text={value} />
            {isExpanded && (
              <div>
                <br />
                {value}
              </div>
            )}
          </div>
        ) : (
          value
        )}
      </MuiTableCell>
    );
  }

  function formatDate(dateString) {
    if (!dateString) {
      return '';
    }

    const datetime = moment(dateString);
    
    if (!datetime.isValid()) {
      return ''; // Handle invalid date strings
    }

    return datetime.format('h:mm A, D MMM YYYY');
  }

  function getStatus(checkInDateTime, checkOutDateTime) {
    if (!checkInDateTime) {
      return 'Belum Check In';
    } else if (!checkOutDateTime) {
      return 'Sudah Check In';
    } else {
      return 'Sudah Check Out';
    }
  }

  async function getAddressByCoordinates(lat, long) {
    if (!lat || !long) {
        return '';
    }

    const result =  await Geocode.fromLatLng(lat, long);

    let address = result?.results[0].formatted_address;

    if(address == "JL. Boulevard Barat Raya, Kelapa Gading, Komplek Plaza Pasific Blok A2/46, Gading Resort Residences, RT.18/RW.8, Klp. Gading Bar., Kec. Klp. Gading, Jkt Utara, Daerah Khusus Ibukota Jakarta 14240, Indonesia"){
      address = "Kantor APT"
    }

    return address ?? 'Address Not Found';
  }

  const columnsTwo = [
    {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'Clockin',
        dataIndex: 'check_in_datetime',
        key: 'check_in_datetime',
    },
    {
        title: 'Clockout',
        dataIndex: 'check_out_datetime',
        key: 'check_out_datetime',
        render: (value) => {            
            if (!value) {
                return <Tag color="warning">Pending</Tag>; // Return null if check_in_datetime is not defined
            }        
            return <text>{value}</text>;
        }
    },
    {
        title: 'Hours',
        dataIndex: 'totalWorked',
        key: 'totalWorked',
    },
    {
        title: 'In Photo',
        dataIndex: 'check_in_image',
        key: 'check_in_image',
        render: (value) => {
            if( value === null ){
            return <Tag color="error">No photo</Tag>;
            }
    
            return (
            <a href={`${SERVER_URL}upload/${value}`} target="_blank" rel="noopener noreferrer">
                <Button type="primary" size="small">View</Button>
            </a>
            );
        }
    },
    {
        title: 'Out Photo',
        dataIndex: 'check_out_image',
        key: 'check_out_image',
        render: (value) => {
            if( value === null ){
                return <Tag color="error">No photo</Tag>;
              }
            
              return (
                <a href={`${SERVER_URL}upload/${value}`} target="_blank" rel="noopener noreferrer">
                  <Button type="primary" size="small">View</Button>
                </a>
              );
        }
    },
    {
        title: 'In Location',
        dataIndex: 'check_in_address',
        key: 'check_in_address',
        render: (value) => <CustomTableCell value={value} />,
      },
      {
        title: 'Out Location',
        dataIndex: 'check_out_address',
        key: 'check_out_address',
        render: (value) => <CustomTableCell value={value} />,
      },
      {
        title: 'Overtime',
        dataIndex: 'overtime_notes',
        key: 'overtime_notes',
      },
  ];

  const switchCheckOnChange = (checked) => {
    setDownloadCSV(checked);
  };

  return (
    <div>
      <Modal title="File download terlalu besar" open={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
        <p>File yang ingin di-download terlalu besar.</p>
        <p>Silahkan filter tanggal untuk mengurangi ukuran download.</p>
      </Modal>
      <Grid container spacing={4} className={classes.tableWrapper}>
        <Grid item xs={6} md={3} className={classes.formContainer}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Text>Start Date</Text>
            <DatePickerAnt onChange={(e) => handleChange(e, 'start_date')} />
          </div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.formContainer}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Text>End Date</Text>
            <DatePickerAnt onChange={(e) => handleChange(e, 'end_date')} />
          </div>
        </Grid>
        <Grid item xs={4} md={3} className={classes.formContainer}>
          <div style={{display: 'flex', flexDirection: "row"}}>
          <div style={{display: 'flex', flexDirection: "column"}}>
            <Text>Download</Text>
            <Button 
                onClick={() => {
                    if (absentData.total > 1000) {
                        setIsModalOpen(true);
                        return;
                    }
                    handleDownload()
                }}
                type="primary"
                size="small"
                style={{
                    width: '125px',
                    height: '30px'
                }}
            >Download</Button>
          </div>
          <div style={{display: 'flex', flexDirection: "column", paddingLeft: '15px'}}>
            <Text>Download Type</Text>
            <Switch 
                checkedChildren="Excel"
                unCheckedChildren="CSV"
                defaultChecked 
                onChange={switchCheckOnChange}
                style={{
                    width: '70px',
                    alignSelf: 'center',
                    marginTop: '4px'
                }}
            />
          </div>
          </div>
        </Grid>
          <Grid item xs={12} md={12}>
            <Table 
                columns={columnsTwo}
                dataSource={absentData?.data}
                pagination={{
                    showSizeChanger: false,
                    total: absentData.total,
                    current: filterList.offset / filterList.limit + 1,
                    onChange: (page) => {
                        console.log(page)
                        console.log(filterList.limit)
                        setFilterList({
                            ...filterList,
                            offset: (page - 1) * filterList.limit
                        })

                        handleScrollToTop()
                    },
                }}
                style={{ border: '0.1px solid #D3D3D3', borderRadius: "5px" }}
            />
          </Grid>
      </Grid>
    </div>
  );
}

export default AbsentView;