import React, { Component } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';
import UploadService from "../../services/upload_pdf/upload-files.service";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

export default class UploadFiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
        };
    }

    // componentDidMount() {
    //     UploadService.getFiles().then((response) => {
    //         this.setState({
    //             fileInfos: response.data,
    //         });
    //     });
    // }

    selectFile(event) {
        console.log('this=>', event.target.files)
        this.setState({
            selectedFiles: event.target.files,
        });
    }

    upload() {
        let fileNameList = []        
        for (let index = 0; index < this.state.selectedFiles.length; index++) {
            let currentFile = this.state.selectedFiles[index];
            this.setState({
                progress: 0,
                currentFile: currentFile,
            });

            UploadService.upload(currentFile, (event) => {
                this.setState({
                    progress: Math.round((100 * event.loaded) / event.total),
                });
            })
                .then((response) => {
                    console.log('pop=>', response.data.fileName.split('/').pop().toString())
                    fileNameList.push(response.data.fileName.split('/').pop().toString())
                    this.setState({
                        message: response.data.message,
                        isError: false
                    });
                    // return UploadService.getFiles();
                })
                .then((files) => {
                    this.setState({
                        fileInfos: files.data,
                    });
                })
                .catch(() => {
                    this.setState({
                        progress: 0,
                        message: "Could not upload the file!",
                        currentFile: undefined,
                        isError: true
                    });
                });

            this.setState({
                selectedFiles: undefined,
            });
        }
        // let currentFile = this.state.selectedFiles[0];
        console.log('fileNameList=>', fileNameList)
        
        this.setState({
            fileInfos: fileNameList
        })
        this.props.setFileList(fileNameList);

    }

    render() {
        const {
            selectedFiles,
            currentFile,
            progress,
            fileInfos,
        } = this.state;
        return (
            <div className="mg20">
                {currentFile && (
                    <Box className="mb25" display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <BorderLinearProgress variant="determinate" value={progress} />
                        </Box>
                        <Box minWidth={35}>
                            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                        </Box>
                    </Box>)
                }

                <label htmlFor="btn-upload">
                    <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        onChange={this.selectFile.bind(this)} />
                    <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span" >
                        Choose Files
                </Button>
                </label>
                {/* <div className="file-name">
                    {selectedFiles && selectedFiles.length > 0 ? {
                        var nameList = []
                        selectedFiles.map(file => {
                            nameList.push(file.name)
                        })
                        return nameList.join(', ')
                    } : null}
                </div> */}
                <Button
                    className="btn-upload"
                    color="primary"
                    variant="contained"
                    component="span"
                    disabled={!selectedFiles}
                    onClick={this.upload.bind(this)}>
                    Upload
              </Button>
{/* 
                <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                    {message}
                </Typography> */}

                <Typography className="list-header">
                    List of Files
                </Typography>
                <ul className="list-group">
                    {fileInfos &&
                        fileInfos.map((file, index) => (
                            <ListItem
                                divider
                                key = {index}
                                >
                                {file}
                            </ListItem>
                        ))}
                </ul>
            </div >
        );
    }
}